@tailwind base;
@tailwind components;
@tailwind utilities;

.recharts-cartesian-axis-tick, .recharts-text .recharts-cartesian-axis-tick-value {
    font-size: 10px !important;
    font-family: monospace;
}

.recharts-text.recharts-label {
    font-size: 10px !important;
    font-family: monospace;
}
